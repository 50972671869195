import React from 'react';
import {useTranslation} from "react-i18next";
import GridFilterPanelLayoutInput
    from "../../../../../../../components/uiLayouts/GridFilterPanelLayout/components/GridFilterPanelLayoutInput/GridFilterPanelLayoutInput";
import {
    useGridFilterPanelLayoutContext
} from "../../../../../../../components/uiLayouts/GridFilterPanelLayout/hooks/useGridFilterPanelLayoutContext";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";
import FilterPanelPeriodFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelPeriodFilter/FilterPanelPeriodFilter";
import FilterPanelRegionFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelRegionFilter/FilterPanelRegionFilter";
import FilterPanelSupplierFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelSupplierFilter/FilterPanelSupplierFilter";
import FilterPanelShipmentConfirmationFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelShipmentConfirmationFilter/FilterPanelShipmentConfirmationFilter";
import FilterPanelServiceProviderFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelServiceProviderFilter/FilterPanelServiceProviderFilter";
import FilterPanelTagsFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelTagsFilter/FilterPanelTagsFilter";
import FilterPanelEquipmentFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelEquipmentFilter/FilterPanelEquipmentFilter";



const ShipmentFilterPanelFiltersSection = () => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const {
        disabled,
        filterToApply,
        applyFilterToApply,
        onKeyDown
    } = useGridFilterPanelLayoutContext();

    if (!user) {
        return null;
    }

    let role = getUserRole(user);

    return (
        <div>
            <div className={'flex-two-columns flex-gap-10 view-row mgb-10'}>
                <div>
                    <GridFilterPanelLayoutInput disabled={disabled}
                                                showClearIcon={true}
                                                value={filterToApply.shipmentNumber ?? ''}
                                                placeholder={t("shipment.grid.order-number-label")}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                    applyFilterToApply(
                                                        'shipmentNumber',
                                                        ev.target.value === ''
                                                            ? null
                                                            : ev.target.value
                                                    );
                                                }}
                                                onClear={() => {
                                                    applyFilterToApply(
                                                        'shipmentNumber',
                                                        null
                                                    );
                                                }}
                                                onKeyDown={(ev) => onKeyDown(ev, 'shipmentNumber')}
                    />
                </div>
                <div>
                    <GridFilterPanelLayoutInput disabled={disabled}
                                                showClearIcon={true}
                                                value={filterToApply.customerRefNumber ?? ''}
                                                placeholder={t("shipment.grid.load-customer-ref-num")}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                    applyFilterToApply(
                                                        'customerRefNumber',
                                                        ev.target.value === ''
                                                            ? null
                                                            : ev.target.value
                                                    );
                                                }}
                                                onClear={() => {
                                                    applyFilterToApply(
                                                        'customerRefNumber',
                                                        null
                                                    );
                                                }}
                                                onKeyDown={(ev) => onKeyDown(ev, 'customerRefNumber')}
                    />
                </div>
            </div>

            <div className={'flex-two-columns flex-gap-10 view-row mgb-10'}>
                <div>
                    <GridFilterPanelLayoutInput disabled={disabled}
                                                showClearIcon={true}
                                                value={filterToApply.licencePlate ?? ''}
                                                placeholder={t("shipment.grid.transport-label")}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                    applyFilterToApply(
                                                        'licencePlate',
                                                        ev.target.value === ''
                                                            ? null
                                                            : ev.target.value
                                                    );
                                                }}
                                                onClear={() => {
                                                    applyFilterToApply(
                                                        'licencePlate',
                                                        null
                                                    );
                                                }}
                                                onKeyDown={(ev) => onKeyDown(ev, 'licencePlate')}
                    />
                </div>
                <div>
                    {role === UserRole.Forwarder || role === UserRole.Administrator
                        ? <GridFilterPanelLayoutInput disabled={disabled}
                                                      showClearIcon={true}
                                                      value={filterToApply.orderReleaseNumber ?? ''}
                                                      placeholder={t("shipment.grid.release-number-label")}
                                                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                          applyFilterToApply(
                                                              'orderReleaseNumber',
                                                              ev.target.value === ''
                                                                  ? null
                                                                  : ev.target.value
                                                          );
                                                      }}
                                                      onClear={() => {
                                                          applyFilterToApply(
                                                              'orderReleaseNumber',
                                                              null
                                                          );
                                                      }}
                                                      onKeyDown={(ev) => onKeyDown(ev, 'orderReleaseNumber')}
                        />
                        : null
                    }
                </div>
            </div>

            <div className={'flex-two-columns flex-gap-10 view-row mgb-10'}>
                <div>
                    {role === UserRole.Forwarder || role === UserRole.Administrator
                        ? <GridFilterPanelLayoutInput disabled={disabled}
                                                      showClearIcon={true}
                                                      value={filterToApply.jobNumber ?? ''}
                                                      placeholder={t("shipment.grid.job-label")}
                                                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                          applyFilterToApply(
                                                              'jobNumber',
                                                              ev.target.value === ''
                                                                  ? null
                                                                  : ev.target.value
                                                          );
                                                      }}
                                                      onClear={() => {
                                                          applyFilterToApply(
                                                              'jobNumber',
                                                              null
                                                          );
                                                      }}
                                                      onKeyDown={(ev) => onKeyDown(ev, 'jobNumber')}
                        />
                        : null
                    }
                </div>
                <div>
                    {role === UserRole.Forwarder || role === UserRole.Administrator
                        ?
                        <>
                            <FilterPanelShipmentConfirmationFilter value={filterToApply.status}
                                                                   onClear={(value) => {
                                                                       applyFilterToApply('status', !value
                                                                           ? null
                                                                           : filterToApply.status.filter(item => item !== value));
                                                                   }}
                                                                   onChange={(values) => {
                                                                       applyFilterToApply('status', values);
                                                                   }}
                            />
                        </>
                        : null
                    }
                </div>
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelPeriodFilter id={'shipment-pickup-date-filter'}
                                         disabled={disabled}
                                         title={t("quote.grid.pickup-date")}
                                         value={{
                                             from: filterToApply.pickupDateFrom,
                                             to: filterToApply.pickupDateTo
                                         }}
                                         onChange={(type, value) => {
                                             applyFilterToApply(type === 'from'
                                                 ? 'pickupDateFrom'
                                                 : 'pickupDateTo', value);
                                         }}
                                         onClear={() => {
                                             applyFilterToApply(
                                                 ['pickupDateFrom', 'pickupDateTo'],
                                                 null
                                             );
                                         }}/>
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelPeriodFilter id={'shipment-delivery-date-filter'}
                                         title={t("quote.grid.delivery-date")}
                                         value={{
                                             from: filterToApply.deliveryDateFrom,
                                             to: filterToApply.deliveryDateTo
                                         }}
                                         onChange={(type, value) => {
                                             applyFilterToApply(type === 'from'
                                                 ? 'deliveryDateFrom'
                                                 : 'deliveryDateTo', value);
                                         }}
                                         onClear={() => {
                                             applyFilterToApply(
                                                 ['deliveryDateFrom', 'deliveryDateTo'],
                                                 null
                                             );
                                         }}/>
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelRegionFilter id={'shipment-source-region-filter'}
                                         title={t("shared.labels.source-region-point")}
                                         onClear={(val) => {
                                             applyFilterToApply('source', !val
                                                 ? null
                                                 : filterToApply.source.filter(e => e.value !== val));
                                         }}
                                         onChange={val => {
                                             applyFilterToApply('source', val);
                                         }}
                                         value={filterToApply.source}/>
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelRegionFilter id={'shipment-destination-region-filter'}
                                         title={t("shared.labels.destination-region-point")}
                                         onClear={(val) => {
                                             applyFilterToApply('destination', !val
                                                 ? null
                                                 : filterToApply.destination.filter(e => e.value !== val));
                                         }}
                                         onChange={val => {
                                             applyFilterToApply('destination', val);
                                         }}
                                         value={filterToApply.destination}/>
            </div>

            {role === UserRole.Forwarder || role === UserRole.Administrator
                ?
                <div className={'view-row mgb-10'}>
                    <FilterPanelSupplierFilter id={'shipment-supplier-filter'}
                                               onClear={(val) => {
                                                   applyFilterToApply('supplier', !val
                                                       ? null
                                                       : filterToApply.supplier.filter(e => e.value !== val));
                                               }}
                                               onChange={val => {
                                                   applyFilterToApply('supplier', val);
                                               }}
                                               value={filterToApply.supplier}/>
                </div>
                : null
            }

            <div className={'view-row mgb-10'}>
                <FilterPanelEquipmentFilter id={'offer-equipment-filter'}
                                            onClear={(val) => {
                                                applyFilterToApply('equipment', !val
                                                    ? null
                                                    : filterToApply.equipment.filter(e => e.value !== val));
                                            }}
                                            onChange={val => {
                                                applyFilterToApply('equipment', val);
                                            }}
                                            value={filterToApply.equipment}/>
            </div>

            {role === UserRole.Forwarder || role === UserRole.Administrator
                ?
                <div className={'view-row mgb-10'}>
                    <FilterPanelServiceProviderFilter id={'shipment-service-provider-filter'}
                                                      onClear={(val) => {
                                                          applyFilterToApply('serviceProvider', !val
                                                              ? null
                                                              : filterToApply.serviceProvider.filter(e => e.value !== val));
                                                      }}
                                                      onChange={val => {
                                                          applyFilterToApply('serviceProvider', val);
                                                      }}
                                                      value={filterToApply.serviceProvider}
                                                      isShipmentFilter={true}
                                                      isNotInBlackList={true}
                                                      isNotInHideStatus={true}/>

                </div>
                : null
            }

            {role === UserRole.Forwarder || role === UserRole.Administrator
                ? <div className={`view-row mgb-10`}>
                    <FilterPanelTagsFilter id={'shipment-tags-filter'}
                                           type={'shipment'}
                                           onClear={(val) => {
                                               applyFilterToApply('tags', !val
                                                   ? null
                                                   : filterToApply.tags.filter(e => e.value !== val));
                                           }}
                                           onChange={(val) => {
                                               applyFilterToApply('tags', val);
                                           }}
                                           value={filterToApply.tags}/>
                </div>
                : null    
            }

        </div>
    );
};

export default ShipmentFilterPanelFiltersSection;
