import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { isAxiosError } from "axios";
import { IRegisterExistingSupplier } from "../../../../app/interfaces/account/IRegisterExistingSupplier";
import Input from "../../../../components/ui/Input/Input";
import { faBuilding, faEnvelope, faFileLines, faPhone, faS, faUser } from "@fortawesome/free-solid-svg-icons";
import Select from "../../../../components/ui/Select/Select";
import Textarea from "../../../../components/ui/Textarea/Textarea";
import Button, { ButtonType } from "../../../../components/ui/Button/Button";
import { IError } from "../../../../app/interfaces/response/IResponse";
import Alert from "../../../../components/ui/Alert/Alert";
import { BLErrorCode } from "../../../../app/enums/BLErrorCode";
import { useAccountApi } from "../../../../app/api/account";
import { ResponseResultCode } from "../../../../app/enums/ResponseResultCode";
import useGoogleReCaptchaV3 from "../../../../hooks/useGoogleReCaptchaV3/useGoogleReCaptchaV3";
import Checkbox, { CheckboxSize } from '../../../../components/ui/Checkbox/Checkbox';
import cl from './ExistingSupplierRegistration.module.css';
import { useCountryApi } from '../../../../app/api/country';
import { SelectModel } from '../../../../app/types/SelectModel';
import { faFlag } from "@fortawesome/free-solid-svg-icons";

const alertStyle: React.CSSProperties = {
    margin: '0px 0px 5px 0px',
    fontSize: '11px',
    padding: '5px 10px',
    fontWeight: 'bold',
    justifyContent: 'center',
    textAlign: 'center'
};

const ExistingSupplierRegistration: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [countries, setCountries] = useState<Array<SelectModel>>([]);
    const [errors, setErrors] = useState<Array<IError>>([]);
    const [isApplicationSent, setIsApplicationSent] = useState<boolean>(false);
    const [selectedCountry, setselectedCountry] = useState<string>('');

    const { loaded, executeReCaptcha } = useGoogleReCaptchaV3({
        siteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '',
        language: i18n.language
    });
    const {
        registerExistingSupplier: {
            mutation
        }
    } = useAccountApi();

    const {
        getCountries: {
            query: getCountries
        }
    } = useCountryApi();

    useEffect(() => {
        (async () => {
            try {
                const response = await getCountries();

                if (response?.status === 200 && response.data) {
                    setCountries(response.data);
                }

            } catch {
                //ignore
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (Object.keys(form.errors).length > 0) {
                await form.validateForm();
            }
        })();
    }, [i18n.language]);

    const form = useFormik<IRegisterExistingSupplier>({
        initialValues: {
            token: '',
            company: '',
            vat: '',
            name: '',
            phone: '',
            email: '',
            sourceFrom: '',
            sourceFromNotes: '',
            privacyPolicyChecked: false,
            country: null,
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object().shape({
            email: Yup.string().nullable().email(t("profile.support.invalid-email"))
                .required(t("shared.errors.required-error").replace('{0}', t("login.registration.contact-person-email"))),
            company: Yup.string().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("login.registration.company-name"))),
            vat: Yup.string().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("login.registration.tax-payer-number"))),
            privacyPolicyChecked: Yup.boolean().isTrue(t("login.registration.privacy-policy-error")),
            sourceFrom: Yup.string().nullable()
                .test('required-source-from',
                    t("shared.errors.required-error").replace('{0}', t("login.registration.source-from")),
                    function (val) {
                        const { sourceFromNotes } = this.parent;

                        if (val !== undefined && val !== null && val === '4') {
                            return !(sourceFromNotes === undefined || sourceFromNotes === null || sourceFromNotes === '');
                        }

                        return !(val === null || val === undefined || val === '' || val === '0');
                    }
                ),
            country: Yup.string().nullable()
                .test('required-country',
                    t("shared.errors.required-error").replace('{0}', t("login.registration.country")),
                    function (val) {
                        return !(val === null || val === undefined || val === '' || val === '0');
                    }
                ),
            phone: Yup.string().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("login.registration.contact-person-phone"))),
            name: Yup.string().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("login.registration.full-name")))
        }),
        onSubmit: async (values: IRegisterExistingSupplier, actions: FormikHelpers<IRegisterExistingSupplier>) => {
            actions.setSubmitting(true);
            setErrors([]);

            try {
                if (!loaded || !executeReCaptcha) {
                    setErrors([{
                        errorCode: BLErrorCode.InnerError,
                        description: t('shared.errors.google-recaptcha-loaded'),
                        field: 'summary'
                    }]);

                    return;
                }

                const token: string = await executeReCaptcha('register/submit');

                if (!token) {
                    setErrors([{
                        errorCode: BLErrorCode.InnerError,
                        description: t('shared.error.google-recaptcha-loaded'),
                        field: 'summary'
                    }]);
                }

                const response = await mutation({
                    ...values,
                    token
                });

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                    setIsApplicationSent(true);
                }
            } catch (err) {
                if (isAxiosError(err) && err.response) {
                    setErrors([
                        {
                            errorCode: BLErrorCode.InnerError,
                            description: `${t("shared.errors.server-error")} (${err.message})`,
                            field: 'summary'
                        }
                    ]);
                }
            }

            actions.setSubmitting(false);
        }
    });

    return (
        <div className={`w100 h100`}>
            {isApplicationSent &&
                <Alert type={'BrightGrayInfo'} style={alertStyle}>
                    {t("login.registration.application-sent")}
                </Alert>
            }

            {errors.map(err => {
                return (
                    <Alert type={'Error'} style={alertStyle} key={err.description}>
                        {err.description}
                    </Alert>
                )
            })}

            {!isApplicationSent &&
                <>
                    <form id={'existing-supplier-form'}
                        onSubmit={form.handleSubmit}>
                        <div style={{ height: '65px', marginTop: '20px' }}>
                            <label className={`view-label required`}>
                                {t("login.registration.company-name")}
                            </label>
                            <Input placeholder={t("login.registration.company-name")}
                                id={'company'}
                                name={'company'}
                                disabled={form.isSubmitting}
                                value={form.values.company ?? ''}
                                autoComplete={'off'}
                                icon={faBuilding}
                                onChange={form.handleChange} />

                            {!form.values.company && form.errors.company &&
                                <div className={'error-label'}>
                                    {form.errors.company}
                                </div>
                            }
                        </div>

                        <div style={{ height: '65px', marginTop: '20px' }}>
                            <label className={`view-label required`}>
                                {t("login.registration.tax-payer-number")}
                            </label>
                            <Input placeholder={t("login.registration.tax-payer-number")}
                                id={'vat'}
                                name={'vat'}
                                disabled={form.isSubmitting}
                                value={form.values.vat ?? ''}
                                autoComplete={'off'}
                                icon={faFileLines}
                                onChange={form.handleChange} />

                            {!form.values.vat && form.errors.vat &&
                                <div className={'error-label'}>
                                    {form.errors.vat}
                                </div>
                            }
                        </div>

                        <div style={{ height: '65px', marginTop: '20px' }}>
                            <label className={`view-label required`}>
                                {t("login.registration.full-name")}
                            </label>
                            <Input placeholder={t("login.registration.full-name")}
                                id={'name'}
                                name={'name'}
                                disabled={form.isSubmitting}
                                value={form.values.name ?? ''}
                                autoComplete={'off'}
                                icon={faUser}
                                onChange={form.handleChange} />

                            {!form.values.name && form.errors.name &&
                                <div className={'error-label'}>
                                    {form.errors.name}
                                </div>
                            }
                        </div>

                        <div style={{ height: '65px', marginTop: '20px' }}>
                            <label className={`view-label required`}>
                                {t("login.registration.contact-person-phone")}
                            </label>
                            <Input placeholder={t("login.registration.contact-person-phone")}
                                id={'phone'}
                                name={'phone'}
                                disabled={form.isSubmitting}
                                value={form.values.phone ?? ''}
                                autoComplete={'off'}
                                icon={faPhone}
                                onChange={form.handleChange} />

                            {!form.values.phone && form.errors.phone &&
                                <div className={'error-label'}>
                                    {form.errors.phone}
                                </div>
                            }
                        </div>

                        <div style={{ height: '65px', marginTop: '20px' }}>
                            <label className={`view-label required`}>
                                {t("login.registration.contact-person-email")}
                            </label>
                            <Input placeholder={t("login.registration.contact-person-email")}
                                id={'email'}
                                name={'email'}
                                disabled={form.isSubmitting}
                                value={form.values.email ?? ''}
                                autoComplete={'off'}
                                icon={faEnvelope}
                                onChange={form.handleChange} />

                            {form.errors.email &&
                                <div className={'error-label'}>
                                    {form.errors.email}
                                </div>
                            }
                        </div>

                        <div style={{ marginTop: '20px' }}>
                            <label className={`view-label required`}>
                                {t("login.registration.source-from")}
                            </label>
                            <Select placeholder={t("login.registration.source-from")}
                                id={'sourceFrom'}
                                name={'sourceFrom'}
                                disabled={form.isSubmitting}
                                autoComplete={'off'}
                                icon={faS}
                                onChange={form.handleChange}
                                value={form.values.sourceFrom ?? '0'}
                                options={[
                                    {
                                        value: '0',
                                        label: t("login.registration.select-source-from")
                                    },
                                    {
                                        value: '1',
                                        label: t("login.registration.source-from-ad-in-search-engine")
                                    },
                                    {
                                        value: '2',
                                        label: t("login.registration.source-from-newsletter")
                                    },
                                    {
                                        value: '3',
                                        label: t("login.registration.source-from-asstra-corporate-site")
                                    },
                                    {
                                        value: '4',
                                        label: t("login.registration.source-from-other")
                                    }
                                ]} />

                            {form.values.sourceFrom !== null && form.values.sourceFrom === '4' &&
                                <div className={'mgt-5'}>
                                    <Textarea id={'sourceFromNotes'}
                                        name={'sourceFromNotes'}
                                        placeholder={t("login.registration.source-from-other-notes")}
                                        disabled={form.isSubmitting}
                                        value={form.values.sourceFromNotes ?? ''}
                                        autoComplete={'off'}
                                        style={{ minHeight: '100px', resize: 'vertical', maxHeight: '150px' }}
                                        onChange={form.handleChange} />
                                </div>
                            }

                            {form.errors.sourceFrom &&
                                <div className={'error-label'}>
                                    {form.errors.sourceFrom}
                                </div>
                            }
                        </div>
                        <div style={{ height: '65px', marginTop: '20px' }}>
                            <label className={`view-label required`}>
                                {t("login.registration.country")}
                            </label>

                            <Select placeholder={t("login.registration.country")}
                                id={'country'}
                                name={'country'}
                                disabled={form.isSubmitting}
                                autoComplete={'off'}
                                icon={faFlag}
                                onChange={(e) => {
                                    form.handleChange(e); // Вызываем сначала существующую логику handleChange
                                    const selectedValue = (e.target as HTMLSelectElement).value;
                                    setselectedCountry(selectedValue);
                                }}
                                value={form.values.country ?? '0'}
                                options={[
                                    {
                                        label: t("login.registration.country"),
                                        value: '0'
                                    },
                                    ...countries.map(country => {
                                        return {
                                            label: country.text,
                                            value: country.value
                                        };
                                    }),
                                ]} />

                            {!form.values.country && form.errors.country &&
                                <div className={'error-label'}>
                                    {form.errors.country}
                                </div>
                            }
                        </div>
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                <Checkbox checked={false}
                                    size={CheckboxSize.Medium}
                                    //label={t("login.registration.privacy-policy-label")}
                                    change={(val) => {
                                        form.setFieldValue("privacyPolicyChecked", val);
                                    }}
                                    disabled={false} />
                                <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                                    <label className={cl.viewLabel}>
                                        {t("login.registration.privacy-policy-label")}
                                    </label>
                                    {selectedCountry === 'RUS' ?
                                        <a href="https://asstra.ru/privacy-policy/" target="_blank" rel="noopener noreferrer" style={{ fontSize: '14px', color: 'blue', textDecoration: 'none' }}>
                                            {t("login.registration.privacy-policy-link-lablel")}
                                        </a>
                                        : <a href="https://asstra.com/privacy-policy/" target="_blank" rel="noopener noreferrer" style={{ fontSize: '14px', color: 'blue', textDecoration: 'none' }}>
                                            {t("login.registration.privacy-policy-link-lablel")}
                                        </a>
                                    }

                                </div>
                            </div>
                            {form.errors.privacyPolicyChecked &&
                                <div className={'error-label'} style={{ marginTop: '8px' }}>
                                    {form.errors.privacyPolicyChecked}
                                </div>
                            }
                        </div>
                    </form>
                    <div className={`mgt-20 mgb-10`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Button buttonType={ButtonType.Primary}
                            type={'submit'}
                            form={'existing-supplier-form'}
                            style={{ minWidth: '180px' }}
                            disabled={form.isSubmitting}
                            isLoading={form.isSubmitting}>
                            {t("shared.btn.send")}
                        </Button>
                    </div>
                </>
            }
        </div>
    );
};

export default ExistingSupplierRegistration;
