import moment from "moment";
import {IInvoice} from "../../../../../app/interfaces/invoice/IInvoice";
import {InvoiceStatus} from "../../../../../app/enums/InvoiceStatus";

export const isPassedPaymentWeek = (week: string | null, netDueDate: string | null) : boolean => {
    if (!week || week === '') {
        return true;
    }

    if (!netDueDate || netDueDate === '') {
        let w1 = parseInt(week);
    
        if (!isNaN(w1)) {
            return w1 > 0 && moment().isoWeek() > w1;
        }
    }
    else{
        let w = parseInt(week);
        let y = parseInt(netDueDate);
    
        if (!isNaN(w) && !isNaN(y)) {
            return w > 0 && moment().isoWeek() > w && y > 0 &&  y <= moment().year();
        }
    }

    return false;
};

export const getInvoiceStatusColor = (status: InvoiceStatus): string => {
    switch (status) {
        case InvoiceStatus.NotPaid:
            return 'afafaf';
        case InvoiceStatus.Paid:
            return '7FB98B';
        case InvoiceStatus.PartiallyPaid:
            return '7D89BA';
    }
};

export const getInvoiceStatusResource = (status: InvoiceStatus): string => {
    switch (status) {
        case InvoiceStatus.NotPaid:
            return 'invoice.status.not-paid';
        case InvoiceStatus.Paid:
            return 'invoice.status.paid';
        case InvoiceStatus.PartiallyPaid:
            return 'invoice.status.partially-paid';
    }
};

export const getInvoiceBlockStatusTitleResource = (invoice: IInvoice): string => {
    if (!invoice.holdStatus || invoice.holdStatus === '') {
        return '';
    }

    if (invoice.holdStatus === 'HOLD_NO_INFO') {
        return 'invoice.grid.block-responsible-fw';
    }

    if (invoice.holdStatus === 'HOLD') {
        return 'invoice.grid.block-responsible-sp';
    }

    return '';
}
