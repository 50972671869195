import React, {useState} from 'react';
import {IInvoice} from "../../../../../../../app/interfaces/invoice/IInvoice";
import {IUser} from "../../../../../../../app/interfaces/user/IUser";
import cl from './ShipmentInvoiceFormInvoiceListCard.module.css';
import Tag from "../../../../../../../components/shared/components/Tag/Tag";
import {
    getInvoiceBlockStatusTitleResource,
    getInvoiceStatusColor,
    getInvoiceStatusResource,
    isPassedPaymentWeek
} from "../../../utils";
import {useTranslation} from "react-i18next";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";
import {useNavigate} from "react-router-dom";
import {InvoiceStatus} from "../../../../../../../app/enums/InvoiceStatus";
import {IShipmentInvoice} from "../../../../../../../app/interfaces/invoice/IShipmentInvoice";
import InvoiceRequestDetailsDialog from "../../../../actions/InvoiceRequestDetails/InvoiceRequestDetailsDialog";
import Icon from "../../../../../../../assets/icon/Icon";
import moment from "moment";
import useMediaQuery from "../../../../../../../hooks/useMediaQuery/useMediaQuery";

type ShipmentInvoiceFormInvoiceListCardProps = {
    data: IShipmentInvoice;
    invoice: IInvoice;
    user?: IUser;
    isSidePanelDetails: boolean;
};

const ShipmentInvoiceFormInvoiceListCard: React.FC<ShipmentInvoiceFormInvoiceListCardProps> = ({
                                                                                                   invoice,
                                                                                                   user,
                                                                                                   data,
                                                                                                   isSidePanelDetails
                                                                                               }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const isMobileView = useMediaQuery('(max-width:1024px)');
    const isMaxWidth960 = useMediaQuery('(max-width: 960px)');
    const isMaxWidth600 = useMediaQuery('(max-width: 600px)');
    const [isBlockRequestDialogShown, setIsBlockRequestDialogShown] = useState<boolean>(false);
    const [isPaymentRequestDialogShown, setIsPaymentRequestDialogShown] = useState<boolean>(false);

    return (
        <>
            {invoice.invoiceGid &&
                <>
                    <InvoiceRequestDetailsDialog user={user}
                                                 invoiceGid={invoice.invoiceGid}
                                                 mode={'Block'}
                                                 show={isBlockRequestDialogShown}
                                                 setShow={setIsBlockRequestDialogShown}/>

                    <InvoiceRequestDetailsDialog user={user}
                                                 invoiceGid={invoice.invoiceGid}
                                                 mode={'Payment'}
                                                 show={isPaymentRequestDialogShown}
                                                 setShow={setIsPaymentRequestDialogShown}/>
                </>
            }

            <div className={cl.container}>
                <div>
                    <div className={'prevent-selection'}>
                        {`${t("invoice.grid.account-number")}`}

                        {!user
                            ? <span className={cl.link}>{invoice.accountNumber}</span>
                            : <>
                                {user && (getUserRole(user) === UserRole.Administrator || getUserRole(user) === UserRole.Forwarder)
                                    ? <span className={`${cl.link} ${cl.linkActive}`}
                                            onClick={(ev) => {
                                                ev.stopPropagation();

                                                navigate(`/invoice/${invoice.invoiceGid}`);
                                            }}>
                                        {invoice.accountNumber}
                                    </span>
                                    : <span className={cl.link}>{invoice.accountNumber}</span>
                                }
                            </>
                        }
                    </div>

                    <div className={cl.tag}>
                        <Tag size={'full'}
                             containerMaxWidth={200}
                             item={{
                                 id: `status-${invoice.id}`,
                                 text: t(getInvoiceStatusResource(invoice.status)),
                                 title: t(getInvoiceStatusResource(invoice.status)),
                                 showDeleteIcon: false,
                                 showEditIcon: false,
                                 showHoverDeleteIcon: false,
                                 showHoverEditIcon: false,
                                 hoverMagnification: false,
                                 backgroundColor: getInvoiceStatusColor(invoice.status),
                             }}/>
                    </div>

                    {invoice.blockedStatus &&
                        <div className={cl.tag}>
                            <Tag size={'full'}
                                 containerMaxWidth={200}
                                 item={{
                                     id: `status-${invoice.id}`,
                                     text: t("invoice.blockStatus.blocked"),
                                     title: t(getInvoiceBlockStatusTitleResource(invoice)),
                                     showDeleteIcon: false,
                                     showEditIcon: false,
                                     showHoverDeleteIcon: false,
                                     showHoverEditIcon: false,
                                     hoverMagnification: false,
                                     backgroundColor: invoice.blockedStatusIndicator ? 'ff0000' : 'afafaf',
                                 }}
                            />
                        </div>
                    }
                </div>

                {(invoice.blockedStatus && (!user || getUserRole(user) === UserRole.ServiceProvider)) ||
                (!invoice.blockedStatus && invoice.status !== InvoiceStatus.Paid && isPassedPaymentWeek(invoice.plannedPaymentWeek, invoice.netDueDate) &&
                    (!user || getUserRole(user) === UserRole.ServiceProvider))
                    ? <div className={cl.btns}>
                        {invoice.blockedStatus && (!user || getUserRole(user) === UserRole.ServiceProvider) &&
                            <button className={`${cl.button} prevent-selection`}
                                    title={t("invoice.form.refine-blocking-details")}
                                    onClick={() => setIsBlockRequestDialogShown(prev => !prev)}>
                                <Icon icon={"faCircleQuestion"}/>
                                <div>
                                    {t("invoice.form.refine-blocking-details")}
                                </div>
                            </button>
                        }

                        {!invoice.blockedStatus && invoice.status !== InvoiceStatus.Paid && isPassedPaymentWeek(invoice.plannedPaymentWeek, invoice.netDueDate) &&
                        (!user || getUserRole(user) === UserRole.ServiceProvider)
                            ? <button className={`${cl.button} prevent-selection`}
                                      title={t("invoice.form.refine-payment-details")}
                                      onClick={() => setIsPaymentRequestDialogShown(prev => !prev)}>
                                <Icon icon={"faCircleQuestion"}/>
                                <div>
                                    {t("invoice.form.refine-payment-details")}
                                </div>
                            </button>
                            : null
                        }
                    </div>
                    : null
                }

                        {invoice.blockedStatus &&
                            <div className={cl.box}>
                                <label>
                                    {t("invoice.form.block-reason")}
                                </label>
                                <div style={{color: 'red'}}>
                                    {`${invoice.blockedReason === null || invoice.blockedReason === '' ? t("shared.labels.no-data") : invoice.blockedReason}`}
                                </div>
                            </div>
                        }



                    {isMobileView || isSidePanelDetails
                        ? <div className={cl.box} data-view={'mobile'}>
                            <div className={cl.col}>
                                <div>
                                    <label>
                                        {t("invoice.grid.invoice-date")}
                                    </label>
                                    <div>
                                        {moment(invoice.invoiceDate).format('DD.MM.YYYY')}
                                    </div>
                                </div>
                                <div>
                                    <label>
                                        {t("invoice.grid.asstra-structure")}
                                    </label>
                                    <div>
                                        {data.billTo}
                                    </div>
                                </div>
                                {invoice.receiveInvoiceDate !== null &&
                                    <div>
                                        <label>
                                            {t("invoice.grid.receive-invoice-date")}
                                        </label>
                                        <div>
                                            {moment(invoice.receiveInvoiceDate).format('DD.MM.YYYY')}
                                        </div>
                                    </div>
                                }

                                {invoice.status === InvoiceStatus.NotPaid &&
                                    invoice.plannedPaymentWeek !== null && invoice.plannedPaymentWeek !== '' &&
                                    <div>
                                        <label>
                                            {t("invoice.form.planned-payment-week")}
                                        </label>
                                        <div>
                                            {invoice.plannedPaymentWeek}
                                        </div>
                                    </div>
                                }
                            </div>



                            <div className={cl.col}>
                                <div>
                                    <label>
                                        {t("invoice.grid.invoice-amount")}
                                    </label>
                                    <div>
                                        {`${invoice.invoiceAmount} ${invoice.invoiceCurrency}`}
                                    </div>
                                </div>

                                <div>
                                    <label>
                                        {t("invoice.form.paid-amount")}
                                    </label>
                                    <div>
                                        {`${invoice.invoicePaidAmount} ${invoice.invoiceCurrency}`}
                                    </div>
                                </div>
                            </div>

                        </div> : <div className={cl.box}>
                            <div className={cl.col}>
                                <div>
                                    <label>
                                        {t("invoice.grid.invoice-date")}
                                    </label>
                                    <div>
                                        {moment(invoice.invoiceDate).format('DD.MM.YYYY')}
                                    </div>
                                </div>
                                <div>
                                    <label>
                                        {t("invoice.grid.asstra-structure")}
                                    </label>
                                    <div>
                                        {data.billTo}
                                    </div>
                                </div>
                            </div>

                            <div className={cl.col}>
                                {invoice.receiveInvoiceDate !== null &&
                                    <div>
                                        <label>
                                            {t("invoice.grid.receive-invoice-date")}
                                        </label>
                                        <div>
                                            {moment(invoice.receiveInvoiceDate).format('DD.MM.YYYY')}
                                        </div>
                                    </div>
                                }

                                {invoice.status === InvoiceStatus.NotPaid &&
                                    invoice.plannedPaymentWeek !== null && invoice.plannedPaymentWeek !== '' &&
                                    <div>
                                        <label>
                                            {t("invoice.form.planned-payment-week")}
                                        </label>
                                        <div>
                                            {invoice.plannedPaymentWeek}
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className={cl.col}>
                                <div>
                                    <label>
                                        {t("invoice.grid.invoice-amount")}
                                    </label>
                                    <div>
                                        {`${invoice.invoiceAmount} ${invoice.invoiceCurrency}`}
                                    </div>
                                </div>

                                <div>
                                    <label>
                                        {t("invoice.form.paid-amount")}
                                    </label>
                                    <div>
                                        {`${invoice.invoicePaidAmount} ${invoice.invoiceCurrency}`}
                                    </div>
                                </div>
                            </div>
                        </div>}


            </div>
        </>
    );
};

export default ShipmentInvoiceFormInvoiceListCard;

/*

<div className={cl.card}>
                <header className={cl.header}>
                    <div>
                        <div className={cl.icon}>
                            <FontAwesomeIcon icon={faFileInvoice}/>
                        </div>

                        <div className={'prevent-selection'}>
                            {`${t("invoice.grid.account-number")}`}

                            {!user
                                ? <span className={cl.link}>{invoice.accountNumber}</span>
                                : <>
                                    {user && (getUserRole(user) === UserRole.Administrator || getUserRole(user) === UserRole.Forwarder)
                                        ? <span className={`${cl.link} ${cl.linkActive}`}
                                                onClick={(ev) => {
                                                    ev.stopPropagation();

                                                    navigate(`/invoice/${invoice.invoiceGid}`);
                                                }}>
                                        {invoice.accountNumber}
                                    </span>
                                        : <span className={cl.link}>{invoice.accountNumber}</span>
                                    }
                                </>
                            }
                        </div>

                        <Tag item={{
                            id: `status-${invoice.id}`,
                            text: t(getInvoiceStatusResource(invoice.status)),
                            title: t(getInvoiceStatusResource(invoice.status)),
                            showDeleteIcon: false,
                            showEditIcon: false,
                            showHoverDeleteIcon: false,
                            showHoverEditIcon: false,
                            hoverMagnification: false,
                            backgroundColor: getInvoiceStatusColor(invoice.status),
                        }}
                             size={'full'}
                             containerMaxWidth={200}/>

                        {invoice.blockedStatus &&
                            <Tag item={{
                                id: `status-${invoice.id}`,
                                text: t("invoice.blockStatus.blocked"),
                                title: t(getInvoiceBlockStatusTitleResource(invoice)),
                                showDeleteIcon: false,
                                showEditIcon: false,
                                showHoverDeleteIcon: false,
                                showHoverEditIcon: false,
                                hoverMagnification: false,
                                backgroundColor: invoice.blockedStatusIndicator ? 'ff0000' : 'afafaf',
                            }}
                                 size={'full'}
                                 containerMaxWidth={200}/>
                        }

                    </div>
                    <div>
                        {invoice.blockedStatus && (!user || getUserRole(user) === UserRole.ServiceProvider) &&
                            <button className={`${cl.button} prevent-selection`}
                                    title={t("invoice.form.refine-blocking-details")}
                                    onClick={() => setIsBlockRequestDialogShown(prev => !prev)}>
                                <FontAwesomeIcon icon={faCircleInfo}/>
                                <div>
                                    {t("invoice.form.refine-blocking-details")}
                                </div>
                            </button>
                        }

                        {!invoice.blockedStatus && invoice.status !== InvoiceStatus.Paid && isPassedPaymentWeek(invoice.plannedPaymentWeek) &&
                        (!user || getUserRole(user) === UserRole.ServiceProvider)
                            ? <button className={`${cl.button} prevent-selection`}
                                      title={t("invoice.form.refine-payment-details")}
                                      onClick={() => setIsPaymentRequestDialogShown(prev => !prev)}>
                                <FontAwesomeIcon icon={faCircleInfo}/>
                                <div>
                                    {t("invoice.form.refine-payment-details")}
                                </div>
                            </button>
                            : null
                        }
                    </div>
                </header>
                <div className={cl.box}>
                    <div className={cl.col}>
                        <div>
                            <label>
                                {t("invoice.grid.invoice-date")}
                            </label>
                            <div>
                                {moment(invoice.invoiceDate).format('DD.MM.YYYY')}
                            </div>
                        </div>
                        <div>
                            <label>
                                {t("invoice.grid.asstra-structure")}
                            </label>
                            <div>
                                {data.billTo}
                            </div>
                        </div>
                    </div>

                    <div className={cl.col}>
                        {invoice.receiveInvoiceDate !== null &&
                            <div>
                                <label>
                                    {t("invoice.grid.receive-invoice-date")}
                                </label>
                                <div>
                                    {moment(invoice.receiveInvoiceDate).format('DD.MM.YYYY')}
                                </div>
                            </div>
                        }

                        {invoice.status === InvoiceStatus.NotPaid &&
                            invoice.plannedPaymentWeek !== null && invoice.plannedPaymentWeek !== '' &&
                            <div>
                                <label>
                                    {t("invoice.form.planned-payment-week")}
                                </label>
                                <div>
                                    {invoice.plannedPaymentWeek}
                                </div>
                            </div>
                        }
                    </div>

                    <div className={cl.col}>
                        <div>
                            <label>
                                {t("invoice.grid.invoice-amount")}
                            </label>
                            <div>
                                {`${invoice.invoiceAmount} ${invoice.invoiceCurrency}`}
                            </div>
                        </div>

                        <div>
                            <label>
                                {t("invoice.form.paid-amount")}
                            </label>
                            <div>
                                {`${invoice.invoicePaidAmount} ${invoice.invoiceCurrency}`}
                            </div>
                        </div>
                    </div>

                    <div className={cl.col}>
                        {invoice.blockedStatus &&
                            <div>
                                <label>
                                    {t("invoice.form.block-reason")}
                                </label>
                                <div style={{color: 'red'}}>
                                    {`${invoice.blockedReason === null || invoice.blockedReason === '' ? t("shared.labels.no-data") : invoice.blockedReason}`}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
 */
