import React, { useCallback, useState } from 'react';
import { IEditTransportGetAction } from "../../../../../../app/interfaces/shipment/IEditTransportGetAction";
import { IEditTransportPostAction } from "../../../../../../app/interfaces/shipment/IEditTransportPostAction";
import { FormikHelpers, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import EditTransportEquipmentSelector from "./EditTransportEquipmentSelector/EditTransportEquipmentSelector";
import Alert from "../../../../../../components/ui/Alert/Alert";
import cl from './EditTransportForm.module.css';
import Input from "../../../../../../components/ui/Input/Input";
import truckIcon from '../../../../../../assets/images/small-truck.png';
import trailerIcon from '../../../../../../assets/images/trailer.png';
import passportIcon from '../../../../../../assets/images/passport-card.png';
import { faA, faCircleInfo, faFlag, faIdCard, faLocationDot, faUser } from '@fortawesome/free-solid-svg-icons';
import Checkbox, { CheckboxSize } from "../../../../../../components/ui/Checkbox/Checkbox";
import PhoneNumberInput from "../../../../../../components/ui/PhoneNumberInput/PhoneNumberInput";
import * as Yup from "yup";
import { isMaskValueValid } from "../../../../../../components/ui/PhoneNumberInput/utils";
import { useAppSelector } from "../../../../../../app/store";
import { selectCurrentUser } from "../../../../../../features/authentication/authenticationSliceSelectors";
import { checkUserInRole } from "../../../../../../helpers/user";
import { UserRole } from "../../../../../../app/enums/UserRole";
import EditTransportTemperatureSelector from './EditTransportTemperatureSelector/EditTransportTemperatureSelector';
import EditTransportFuelSelector from './EditTransportFuelSelector/EditTransportFuelSelector';
import { isAxiosError } from 'axios';
import { toast } from "react-toastify";
import { useDataHelperApi } from '../../../../../../app/api/dataHelper';
import { ResponseResultCode } from '../../../../../../app/enums/ResponseResultCode';
import EditTransportLoadSelector from './EditTransportLoadSelector/EditTransportLoadSelector';
import EditTransportVehicleForGreenRouterSelector from './EditTransportVehicleForGreenRouterSelector/EditTransportVehicleForGreenRouterSelector';
import {debounce} from "debounce";
import { IError } from '../../../../../../app/interfaces/response/IResponse';
import { BLErrorCode } from '../../../../../../app/enums/BLErrorCode';

type EditTransportFormProps = {
    model: IEditTransportGetAction;
    disabled: boolean;
    save: (model: IEditTransportPostAction) => Promise<void>;
    errors: Array<IError>;
}

const EditTransportForm: React.FC<EditTransportFormProps> = ({
    model,
    save,
    disabled,
    errors
}) => {
    const { t } = useTranslation();
    const user = useAppSelector(selectCurrentUser);
    const [showLicencePlateError, setShowLicencePlateError] = useState<boolean>(false);
    const [showLatinLetterError, setShowLatinLetterError] = useState<boolean>(false);
    const [showSuggestionTruckPanel, setShowSuggestionTruckPanel] = useState<boolean>(false);
    const [showSuggestionTrailerPanel, setShowSuggestionTrailerPanel] = useState<boolean>(false);

    const [suggestXPosition, setSuggestXPosition] = useState<string | number>(0);
    const [suggestYPosition, setSuggestYPosition] = useState<string | number>(0);
    const [suggestWidth, setSuggestWidth] = useState<string | number>(0);
    const [suggestFieldName, setSuggestFieldName] = useState<string>('');


    const [trucksForSuggest, setTrucksForSuggest] = useState<Array<string>>();
    const [trailersForSuggest, _setTrailersForSuggest] = useState<Array<string>>();

    const {
        getAtmospherePrefilledValues: {
            query: getAtmospherePrefilledValues,
            isLoading: isLoadingAtmospherePrefilledValues,
            cancel
        }
    } = useDataHelperApi();

    const alertStyle: React.CSSProperties = {
        margin: '10px 0',
        fontSize: '14px',
        padding: '10px'
    }

    const form = useFormik<IEditTransportPostAction>({
        initialValues: {
            noDriverPhoneProvided: model.noDriverPhoneProvided,
            isOnlyLatin: model.isOnlyLatin,
            orderReleaseGid: model.orderReleaseGid,
            shipmentGid: model.shipmentGid,
            equipment: model.equipment,
            truckOld: model.truckOld,
            trailerOld: model.trailerOld,
            truck: model.truck,
            trailer: model.trailer,
            driver: model.driver,
            driverPhoneCode: model.driverPhoneCode,
            driverPhone: model.driverPhone,
            driverPhoneCountry: model.driverPhoneCountry,
            passport: model.passport,
            driverLicense: model.driverLicense,
            aliasNum: model.aliasNum,
            nationality: model.nationality,
            truckMark: model.truckMark,
            truckModel: model.truckModel,
            trackingLink: model.trackingLink,
            phoneFormat: null,
            isReload: model.isReload,
            isTransportChanged: false,
            temperature: model.temperature,
            fuel: model.fuel,
            load: model.load,
            vehicleForGreenRouter: model.vehicleForGreenRouter,
            fuelTree: model.fuelTree,
            loadTree: model.loadTree,
            vehicleForGreenRouterTree: model.vehicleForGreenRouterTree
        },
        validationSchema: Yup.object().shape({
            driverPhone: Yup.string().nullable()
                .test('required-phone',
                    t("shared.errors.required-error").replace('{0}', t("shipment.grid.mobile-label")),
                    function (val) {
                       const { noDriverPhoneProvided } = this.parent;

                        //console.log(noDriverPhoneProvided);
                        if (!model.isPhoneRequired || noDriverPhoneProvided === true) {
                            return true;
                        }

                        return !(val === null || val === undefined || val === '');
                    }
                )
                .test('is-mask-value-valid',
                    t("shipment.errors.edit-transport-mobile-phone-not-valid"),
                    function (val) {
                        const { phoneFormat } = this.parent;
                        const { noDriverPhoneProvided } = this.parent;
                        //console.log(this.parent);           
                        if (val === null || val === undefined || val === '' || phoneFormat === null
                            || noDriverPhoneProvided === true) {
                            return true;
                        }

                        //phoneFormat.mask === undefined это единственный случай, для Сербии
                        if (phoneFormat.mask === undefined)
                            return true;

                        return isMaskValueValid(val, phoneFormat.mask);
                    }
                ),

            fuel: Yup.string().nullable()
                .test('required-fuel',
                    t("shared.errors.required-error").replace('{0}', t("shipment.grid.fuel-label")),
                    function (val) {
                        return !(val === null || val === undefined || val === '');
                    }
                ),

            load: Yup.string().nullable()
                .test('required-load',
                    t("shared.errors.required-error").replace('{0}', t("shipment.grid.load-label")),
                    function (val) {
                        return !(val === null || val === undefined || val === '');
                    }
                ),
            vehicleForGreenRouter: Yup.string().nullable()
                .test('required-vehicle',
                    t("shared.errors.required-error").replace('{0}', t("shipment.grid.vehicle-for-green-router-label")),
                    function (val) {
                        return !(val === null || val === undefined || val === '');
                    }
                ),

        }),
        onSubmit: async (values: IEditTransportPostAction, actions: FormikHelpers<IEditTransportPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    const validateLicencePlate = (value?: string | null, _fieldValue?: string | null): boolean => {
        let isValid: boolean = true;



        if (value && !value.match(/^[A-Za-z0-9]+$/)) {
            isValid = false;
        }


        if (!isValid) {
            setShowLicencePlateError(true);
        }
        else
        {
            setShowLicencePlateError(false);
        }


        return isValid;
    }

    const validateLatinLetter = (value?: string): boolean => {
        let isValid: boolean = true;

        if (model.isOnlyLatin && value && value.match(/^.*[ёЁА-Яа-я]+.*$/)) {
            isValid = false;
        }

        if (!isValid && !showLatinLetterError) {
            setShowLatinLetterError(true);
        }

        return isValid;
    }

    const onKeyDownTrailerPlate = (ev: React.KeyboardEvent<HTMLInputElement>, value?: string | null) => {
        if (ev.keyCode === 0 || ev.keyCode === 8 || ev.ctrlKey) {
            return;
        }

        if (value && value.length == 14) {
            ev.preventDefault();
            return
        }

        if (!validateLicencePlate(ev.key, value)) {
            ev.preventDefault();
            return
        }


        if (value && (value + "" + ev.key).length > 1) {

            setTrucksForSuggest(model.trailersForSuggest.filter(e => e.toLowerCase().startsWith((value + "" + ev.key).toLowerCase())))

            setSuggestFieldName(ev.currentTarget.name)
            setSuggestXPosition(ev.currentTarget.offsetLeft)
            setSuggestYPosition(ev.currentTarget.offsetTop + ev.currentTarget.clientHeight)
            setSuggestWidth(ev.currentTarget.clientWidth)
            setShowSuggestionTrailerPanel(true)
        } else {
            setShowSuggestionTrailerPanel(false)
        }
    }

    const trailerSelected = (text: string) => {

        form.setFieldValue(suggestFieldName, text);

        if (suggestFieldName == "trailer")
            handleTransportChange(undefined, undefined, text, undefined);
        if (suggestFieldName == "trailerOld")
            handleTransportChange(text, undefined, undefined, undefined);
        setShowSuggestionTrailerPanel(false)
    }
    const truckSelected = (text: string) => {
        form.setFieldValue(suggestFieldName, text);

        if (suggestFieldName == "truck")
            handleTransportChange(undefined, text, undefined, undefined);
        if (suggestFieldName == "truckOld")
            handleTransportChange(undefined, undefined, undefined, text);

        handleTrackOrEquipmentChange(text, form.values.equipment);
        
        setShowSuggestionTruckPanel(false);
    }


    const hideSuggestion = () => {

        setTimeout(() => {
            setShowSuggestionTrailerPanel(false)
            setShowSuggestionTruckPanel(false)

        },
            2000
        )

    }
    const onKeyDownTruckPlate = (ev: React.KeyboardEvent<HTMLInputElement>, value?: string | null) => {
        if (ev.keyCode === 0 || ev.keyCode === 8 || ev.ctrlKey) {
            return;
        }

        if (value && value.length == 10) {
            ev.preventDefault();
            return
        }

        if (!validateLicencePlate(ev.key, value)) {
            ev.preventDefault();
            return
        }


        if (value && (value + "" + ev.key).length > 1) {

            setTrucksForSuggest(model.trucksForSuggest.filter(e => e.toLowerCase().startsWith((value + "" + ev.key).toLowerCase())))

            setSuggestFieldName(ev.currentTarget.name)
            setSuggestXPosition(ev.currentTarget.offsetLeft)
            setSuggestWidth(ev.currentTarget.clientWidth)
            setSuggestYPosition(ev.currentTarget.offsetTop + ev.currentTarget.clientHeight)
            setShowSuggestionTruckPanel(true)
        } else {
            setShowSuggestionTruckPanel(false)
        }
    }


    const onKeyDownDriverData = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.keyCode === 0 || ev.keyCode === 8 || ev.ctrlKey) {
            return;
        }

        if (!validateLatinLetter(ev.key)) {
            ev.preventDefault();
        }
    }

    const handleDriverChange = (driver?: string, nationality?: string, passport?: string, driverLicense?: string) => {
        validateLatinLetter(driver)
        validateLatinLetter(nationality)
        validateLatinLetter(passport)
        validateLatinLetter(driverLicense)
    }

    const handleTransportChange = (trailer?: string, truck?: string, trailerOld?: string, truckOld?: string) => {
        if (model.trailer !== (trailer ?? form.values.trailer) || model.truck !== (truck ?? form.values.truck) ||
            model.trailerOld !== (trailerOld ?? form.values.trailerOld) || model.truckOld !== (truckOld ?? form.values.truckOld)) {
            form.setFieldValue('isTransportChanged', true);
        } else {
            form.setFieldValue('isTransportChanged', false);
            form.setFieldValue('isReload', false);
        }

        validateLicencePlate(truck, truck)
        validateLicencePlate(truckOld, truckOld)
        validateLicencePlate(trailer, trailer)
        validateLicencePlate(trailerOld, trailerOld)
    };


    const handleTrackOrEquipmentChange = useCallback(debounce(async (truck: string | null, equipment: string | null) => {
        try {

            //debounce позволяет НЕ отправлять запросы к апи при каждом изменении инпута
            //мы отправляем запросы к апи через секунду после последнего изменения инпута
            //это помогло решить проблему с тем, что ответы от апи приходили и обрабатывались
            //не в том порядке в котором они отправлялись к апи
            const response = await getAtmospherePrefilledValues(model.shipmentGid, truck, equipment);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok &&
                response.data?.data) {
                    form.setFieldValue('fuelTree', response.data.data.fuelTree);
                    form.setFieldValue('loadTree', response.data.data.loadTree);
                    form.setFieldValue('vehicleForGreenRouterTree', response.data.data.vehicleForGreenRouterTree);
                    form.setFieldValue('fuel', response.data.data.fuel);
                    form.setFieldValue('load', response.data.data.load);
                    form.setFieldValue('vehicleForGreenRouter', response.data.data.vehicleForGreenRouter);

                }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to get AtmospherePrefilledValues. Error: ${e.message}`);
            }
        }
    },1000),[]);


    return (
        <form id={'edit-transport-form'}
            onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'mgb-20 drop-down-picker'}>
                    <label
                        className={'view-label edit-transport-equipment-label'}>{t("shipment.grid.equipment-label")}</label>
                    <EditTransportEquipmentSelector disabled={disabled}
                        equipment={form.values.equipment}
                        onChange={(ev) => {
                            form.setFieldValue('equipment', ev.target.value.value);
                            handleTrackOrEquipmentChange(form.values.truck, ev.target.value.value);
                        }} />
                </div>


                <Alert type={'BrightGrayInfo'}
                    id={'edit-transport-licence-plates-warning'}
                    style={{
                        margin: '10px 0',
                        fontSize: '14px',
                        padding: '10px'
                    }}>
                    {t("shipment.warnings.edit-transport-licence-plate")}
                </Alert>

                {errors.map(item => {
                    switch (item.errorCode) {
                        case BLErrorCode.DriverDataRequired:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("shipment.errors.edit-transport-driver-info-required")}
                                </Alert>
                            );
                    }

                    return null;
                })}

                {showLicencePlateError &&
                    <Alert type={'Error'}
                        id={'edit-transport-licence-plates-error'}
                        style={{
                            margin: '10px 0',
                            fontSize: '14px',
                            padding: '10px'
                        }}>
                        {t("shipment.errors.edit-transport-licence-plate")}
                    </Alert>
                }
                {showLatinLetterError &&
                    <Alert type={'Error'}
                        id={'edit-transport-latin-letter-error'}
                        style={{
                            margin: '10px 0',
                            fontSize: '14px',
                            padding: '10px'
                        }}>
                        {t("shipment.errors.edit-transport-latin-letter-only")}
                    </Alert>
                }

                <div >
                    {showSuggestionTruckPanel && trucksForSuggest && trucksForSuggest.length > 0 && <ul className={cl.suggest} style={{ width: suggestWidth, top: suggestYPosition, left: suggestXPosition }}>
                        {trucksForSuggest.map(i => {
                            return (
                                <li onClick={() => { truckSelected(i) }} style={{ display: 'block', padding: '5px' }}>{i}</li>
                            );
                        })}
                    </ul>}
                    {showSuggestionTrailerPanel && trailersForSuggest && trailersForSuggest.length > 0 && <ul className={cl.suggest} style={{ width: suggestWidth, top: suggestYPosition, left: suggestXPosition }}>
                        {trailersForSuggest.map(i => {
                            return (
                                <li onClick={() => { trailerSelected(i) }} style={{ display: 'block', padding: '5px' }}>{i}</li>
                            );
                        })}
                    </ul>}
                    {model.truckOld !== null && model.truckOld !== ''
                        ? <div className={cl.group}>
                            <div>
                                <label
                                    className={'view-label edit-transport-truck-label'}>{t("shipment.form.truck")}</label>
                                <Input disabled={disabled}
                                    autoComplete={'off'}
                                    name={'truckOld'}
                                    id={'truckOld'}
                                    onKeyDown={(ev) => onKeyDownTruckPlate(ev, form.values.truckOld)}
                                    onBlur={hideSuggestion}
                                    //  onPaste={(ev) => onPastePlate(ev, form.values.truckOld)}
                                    placeholder={t("shipment.form.truck")}
                                    value={form.values.truckOld ?? ''}
                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                        
                                        handleTransportChange(undefined, undefined, undefined, ev.target.value);

                                        handleTrackOrEquipmentChange(ev.target.value, form.values.equipment);
                                        form.handleChange(ev);
                                    }}
                                    image={truckIcon}
                                    className={'edit-transport-truck-value'}
                                />
                            </div>
                            <div>
                                <label
                                    className={'view-label edit-transport-trailer-label'}>{t("shipment.form.trailer")}</label>
                                <Input disabled={disabled}
                                    autoComplete={'off'}
                                    name={'trailerOld'}
                                    id={'trailerOld'}
                                    onBlur={hideSuggestion}
                                    onKeyDown={(ev) => onKeyDownTrailerPlate(ev, form.values.trailerOld)}
                                    //   onPaste={(ev) => onPastePlate(ev, form.values.trailerOld)}
                                    placeholder={t("shipment.form.trailer")}
                                    value={form.values.trailerOld ?? ''}
                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                        handleTransportChange(undefined, undefined, ev.target.value, undefined);

                                        form.handleChange(ev);
                                    }}
                                    image={trailerIcon}
                                    className={'edit-transport-trailer-value'}
                                />
                            </div>
                        </div>
                        : null
                    }
                    <div className={cl.group}>
                        <div>
                            {model.truckOld !== null && model.truckOld !== ''
                                ? <label
                                    className={'view-label edit-transport-new-truck-label'}>{t("shipment.form.new-truck")}</label>
                                : <label
                                    className={'view-label edit-transport-truck-label'}>{t("shipment.form.truck")}</label>
                            }
                            <Input disabled={disabled}
                                autoComplete={'off'}
                                name={'truck'}
                                id={'truck'}
                                onBlur={hideSuggestion}
                                onKeyDown={(ev) => onKeyDownTruckPlate(ev, form.values.truck)}
                                // onPaste={(ev) => onPastePlate(ev, form.values.truck)}
                                placeholder={model.truckOld !== null && model.truckOld !== ''
                                    ? t("shipment.form.new-truck")
                                    : t("shipment.form.truck")
                                }
                                value={form.values.truck ?? ''}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                    handleTransportChange(undefined, ev.target.value, undefined, undefined);

                                    handleTrackOrEquipmentChange(ev.target.value, form.values.equipment);
                                    form.handleChange(ev);
                                }}
                                image={truckIcon}
                                className={'edit-transport-truck-value'}
                            />
                        </div>
                        <div>
                            {model.truckOld !== null && model.truckOld !== ''
                                ? <label
                                    className={'view-label edit-transport-new-trailer-label'}>{t("shipment.form.new-trailer")}</label>
                                : <label
                                    className={'view-label edit-transport-trailer-label'}>{t("shipment.form.trailer")}</label>
                            }
                            <Input disabled={disabled}
                                autoComplete={'off'}
                                name={'trailer'}
                                id={'trailer'}
                                onBlur={hideSuggestion}
                                onKeyDown={(ev) => onKeyDownTrailerPlate(ev, form.values.trailer)}
                                // onPaste={(ev) => onPastePlate(ev, form.values.trailer)}
                                placeholder={model.truckOld !== null && model.truckOld !== ''
                                    ? t("shipment.form.new-trailer")
                                    : t("shipment.form.trailer")
                                }
                                value={form.values.trailer ?? ''}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                    handleTransportChange(ev.target.value, undefined, undefined, undefined);

                                    form.handleChange(ev);
                                }}
                                image={trailerIcon}
                                className={'edit-transport-trailer-value'}
                            />
                        </div>
                    </div>

                    {model.isReloadVisible && form.values.isTransportChanged &&
                        <Alert type={'BrightGrayInfo'}
                            id={'edit-transport-licence-plates-warning'}
                            style={{
                                margin: '10px 0',
                                fontSize: '14px',
                                padding: '10px'
                            }}>
                            <Checkbox checked={form.values.isReload}
                                id={'edit-transport-is-reload'}
                                label={t("shipment.form.is-reload-label")}
                                title={'lol'}
                                change={(val) => {
                                    form.setFieldValue('isReload', val)
                                }}
                                disabled={disabled || form.isSubmitting} />
                        </Alert>
                    }

                    <div className={cl.group}>
                        <div>
                            <label
                                className={'view-label edit-transport-truck-mark-label'}>{t("shipment.form.truck-mark")}</label>
                            <Input disabled={disabled}
                                autoComplete={'off'}
                                name={'truckMark'}
                                id={'truckMark'}
                                placeholder={t("shipment.form.truck-mark")}
                                value={form.values.truckMark ?? ''}
                                onChange={form.handleChange}
                                icon={faCircleInfo}
                                className={'edit-transport-truck-mark-value'}
                            />
                        </div>
                        <div>
                            <label
                                className={'view-label edit-transport-truck-model-label'}>{t("shipment.form.truck-model")}</label>
                            <Input disabled={disabled}
                                autoComplete={'off'}
                                name={'truckModel'}
                                id={'truckModel'}
                                placeholder={t("shipment.form.truck-model")}
                                value={form.values.truckModel ?? ''}
                                onChange={form.handleChange}
                                icon={faCircleInfo}
                                className={'edit-transport-truck-model-value'}
                            />
                        </div>
                    </div>
                    <div className={cl.group}>
                        <div>
                            <label
                                className={'view-label edit-transport-alias-number-label'}>{t("shipment.form.alias-number")}</label>
                            <Input disabled={disabled}
                                autoComplete={'off'}
                                name={'aliasNum'}
                                id={'aliasNum'}
                                placeholder={t("shipment.form.alias-number")}
                                value={form.values.aliasNum ?? ''}
                                onChange={form.handleChange}
                                icon={faA}
                                className={'edit-transport-alias-number-value'}
                            />
                        </div>
                        <div>
                            <label
                                className={'view-label edit-transport-tracking-link-label'}>{t("shipment.form.tracking-link")}</label>
                            <Input disabled={disabled}
                                autoComplete={'off'}
                                name={'trackingLink'}
                                id={'trackingLink'}
                                placeholder={t("shipment.form.tracking-link")}
                                value={form.values.trackingLink ?? ''}
                                onChange={form.handleChange}
                                icon={faLocationDot}
                                className={'edit-transport-tracking-link-value'}
                            />
                        </div>
                    </div>
                    <div className={`${cl.group} ${cl.start}`}>
                        <div className={'drop-down-picker'}>
                            <label
                                className={'view-label edit-transport-temperature-label'}>{t("shipment.grid.temperature-label")}</label>
                            <EditTransportTemperatureSelector disabled={disabled}
                                temperature={form.values.temperature}
                                onChange={(ev) => {
                                    form.setFieldValue('temperature', ev.target.value.value);
                                }} />
                        </div>

                        <div className={'drop-down-picker'}>
                            <label
                                className={'view-label edit-transport-fuel-label required'}>{t("shipment.grid.fuel-label")}</label>
                            <EditTransportFuelSelector disabled={disabled}
                                fuel={form.values.fuel}
                                shipmentGid={model.shipmentGid}
                                data={form.values.fuelTree}
                                isLoadingPrefilledValue={isLoadingAtmospherePrefilledValues}
                                onChange={(ev) => {
                                    form.setFieldValue('fuel', ev.target.value.value);
                                }} />

                            {!form.values.fuel && form.errors.fuel &&
                                <div className={`error-label ${cl.error}`}>
                                    {form.errors.fuel}
                                </div>
                            }
                        </div>
                    </div>

                    <div className={`${cl.group} ${cl.start}`}>
                        <div className={'drop-down-picker'}>
                            <label
                                className={'view-label edit-transport-vehicle-label required'}>{t("shipment.grid.vehicle-for-green-router-label")}</label>
                            <EditTransportVehicleForGreenRouterSelector disabled={disabled}
                                vehicleForGreenRouter={form.values.vehicleForGreenRouter}
                                shipmentGid={model.shipmentGid}
                                data={form.values.vehicleForGreenRouterTree}
                                isLoadingPrefilledValue={isLoadingAtmospherePrefilledValues}
                                onChange={(ev) => {
                                    form.setFieldValue('vehicleForGreenRouter', ev.target.value.value);
                                }} />

                            {!form.values.vehicleForGreenRouter && form.errors.vehicleForGreenRouter &&
                                <div className={`error-label ${cl.error}`}>
                                    {form.errors.vehicleForGreenRouter}
                                </div>
                            }
                        </div>
                        <div className={'drop-down-picker'}>
                            <label
                                className={'view-label edit-transport-load-label required'}>{t("shipment.grid.load-label")}</label>
                            <EditTransportLoadSelector disabled={disabled}
                                load={form.values.load}
                                shipmentGid={model.shipmentGid}
                                data={form.values.loadTree}
                                isLoadingPrefilledValue={isLoadingAtmospherePrefilledValues}
                                onChange={(ev) => {
                                    form.setFieldValue('load', ev.target.value.value);
                                }} />

                            {!form.values.load && form.errors.load &&
                                <div className={`error-label ${cl.error}`}>
                                    {form.errors.load}
                                </div>
                            }
                        </div>
                    </div>
                    <div className={cl.driverLabel}>
                        {t("shipment.form.driver")}
                    </div>
                    <div className={cl.group}>
                        <div>
                            <label
                                className={'view-label edit-transport-driver-name-label'}>{t("shipment.form.driver-full-name")}</label>
                            <Input disabled={disabled}
                                autoComplete={'off'}
                                name={'driver'}
                                id={'driver'}
                                onKeyDown={onKeyDownDriverData}
                                //    onPaste={onPasteDriverData}
                                placeholder={t("shipment.form.driver-full-name")}
                                value={form.values.driver ?? ''}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                    handleDriverChange(ev.target.value, undefined, undefined, undefined);

                                    form.handleChange(ev);
                                }}
                                icon={faUser}
                                className={'edit-transport-driver-name-value'}
                            />
                        </div>
                        <div>
                            <label
                                className={'view-label edit-transport-driver-nationality-label'}>{t("shipment.form.driver-nationality")}</label>
                            <Input disabled={disabled}
                                autoComplete={'off'}
                                name={'nationality'}
                                id={'nationality'}
                                onKeyDown={onKeyDownDriverData}
                                //   onPaste={onPasteDriverData}
                                placeholder={t("shipment.form.driver-nationality")}
                                value={form.values.nationality ?? ''}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                    handleDriverChange(undefined, ev.target.value, undefined, undefined);

                                    form.handleChange(ev);
                                }}
                                icon={faFlag}
                                className={'edit-transport-driver-nationality-value'}
                            />
                        </div>
                    </div>
                    <div className={cl.group}>
                        <div>
                            <label
                                className={'view-label edit-transport-driver-passport-label'}>{t("shipment.form.driver-passport")}</label>
                            <Input disabled={disabled}
                                autoComplete={'off'}
                                name={'passport'}
                                id={'passport'}
                                onKeyDown={onKeyDownDriverData}
                                // onPaste={onPasteDriverData}
                                placeholder={t("shipment.form.driver-passport")}
                                value={form.values.passport ?? ''}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                    handleDriverChange(undefined, undefined, ev.target.value, undefined);

                                    form.handleChange(ev);
                                }}
                                image={passportIcon}
                                className={'edit-transport-driver-passport-value'}
                            />
                        </div>
                        <div>
                            <label
                                className={'view-label edit-transport-driving-licence-label'}>{t("shipment.form.driver-driving-licence")}</label>
                            <Input disabled={disabled}
                                autoComplete={'off'}
                                name={'driverLicense'}
                                id={'driverLicense'}
                                onKeyDown={onKeyDownDriverData}
                                //  onPaste={onPasteDriverData}
                                placeholder={t("shipment.form.driver-driving-licence")}
                                value={form.values.driverLicense ?? ''}

                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                    handleDriverChange(undefined, undefined, undefined, ev.target.value);

                                    form.handleChange(ev);
                                }}
                                icon={faIdCard}
                                className={'edit-transport-driving-licence-label'}
                            />
                        </div>
                    </div>
                    <div className={cl.group}>
                        <div>
                            <label
                                className={`view-label edit-transport-driver-mobile-label ${model.isPhoneRequired ? 'required' : ''}`}>
                                {t("shipment.grid.mobile-label")}
                            </label>
                            <div className={disabled || form.values.noDriverPhoneProvided ? cl.disabledPhoneInput : ''}>
                            <PhoneNumberInput 
                            disabled={disabled || form.values.noDriverPhoneProvided}
                                phoneCode={form.values.driverPhoneCode} showIcon={false}
                                phoneNumber={form.values.driverPhone}
                                onPhoneCodeChange={(val) => {
                                    form.setFieldValue('phoneFormat', val);
                                    form.setFieldValue('driverPhoneCode', val.phoneCode);
                                    form.setFieldValue('driverPhoneCountry', val.country);
                                }}
                                onPhoneNumberChange={(val) => {
                                    form.setFieldValue('driverPhone', val);
                                }}
                            />
                            </div>
                            {form.errors.driverPhone &&
                                <div className={'error-label'}>
                                    {form.errors.driverPhone}
                                </div>
                            }
                            {model.isPhoneRequired && !checkUserInRole(UserRole.ServiceProvider, user) &&
                                <div style={{ margin: '5px 0' }}>
                                    <Checkbox checked={form.values.noDriverPhoneProvided}
                                        id={'edit-transport-no-driver-phone-provided'}
                                        title={t("shipment.form.carrier-prohibited-data-sharing")}
                                        label={t("shipment.form.carrier-prohibited-data-sharing")}
                                        change={(ev) => {
                                            
                                            

                                            form.setFieldValue('driverPhoneCode', null);
                                            form.setFieldValue('driverPhoneCountry', null);
                                            form.setFieldValue('driverPhone', null);

                                            form.setFieldValue('noDriverPhoneProvided', ev);
                                            /*form.validateForm().then(() => {
                                                //ignore
                                            });*/
                                        }}
                                        disabled={disabled} />
                                </div>
                            }
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>

        </form>
    );
};

export default EditTransportForm;
